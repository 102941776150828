<template>
   <div class="grid grid-cols-3">
      <div class="p-10 text-center">
         <div class="flex justify-center mb-5 col-span-2">
            <div class="relative">
               <div class="rounded-full w-32 h-32 py-8 mx-auto bg-white border border-gray-200 cursor-pointer hover:bg-gray-50 transition-colors hover:border-gray-300">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 m-auto my-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                     <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                  </svg>
                  <p class="font-medium text-sm text-gray-500">Add Contact</p>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
   name: "Contacts",
   
   components: {
         
   },

   data() {
      return {

      }
   },

   methods: {

   },

   computed: {
      ...mapGetters('auth', ['user'])
   }
}
</script>